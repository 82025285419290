import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";

import { CountryCodesPicker } from "../common/CountryCodesPicker";
import { FieldWrapper } from "../common/FieldWrapper";
import { TextField } from "../common/TextFeild";
import A from "../../utils/urls.utils";
import NavLinks from "../../utils/navLinks";
import useAdmin from "../hooks/useAdmin";
import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import useFirebase from "../hooks/fireBaseConfig";
import { Modal } from "react-bootstrap";
import Countdown from "react-countdown";
import Crypto from "crypto-js";
import constantUtils from "../../utils/constantUtils";

export default function Login() {
  const { settings } = useSettings();
  const { language } = useLanguage();
  const { authentication } = useFirebase();

  const { popUp } = useParams();

  document.title = "LOG_IN";
  const {
    user,
    userProfile,
    // userProfileData,
    userProfileFetch,
    userLogIn,
    header,
    ipAddress,
  } = useAdmin();
  const navigate = useNavigate();

  // STATES
  const [step, setStep] = useState(1);
  const [keepLogged, setKeepLogged] = useState(true);
  const [error, setError] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [showReSendOtp, setShowReSendOtp] = useState(false);
  const [resendLoading, setResendLoading] = useState(true);
  const [email, setEmail] = useState("");
  const [showGoogle, setShowGoogle] = useState(false);
  const [errorPopup, setErrorPopup] = useState(false);
  const [popUpContent, setPopUpContent] = useState("");
  const [mode, setMode] = useState("");

  // const [bookingDetails, setBookingDetails] = useState({
  //   phoneCode: null,
  //   phoneNumber: null,
  //   bookingType: "INSTANT",
  //   paymentMethod: "CASH",
  //   category: null,
  //   date: new Date(new Date().setMinutes(new Date().getMinutes() + 70)),
  //   manualAssign: false,
  //   walletBook: false,
  //   time: new Date(new Date().setMinutes(new Date().getMinutes() + 70)),
  // });
  useEffect(() => {
    console.log(settings);

    window.addEventListener(
      "popstate",
      async (event) => {
        setTimeout(window.history.forward(), 0);
        // console.log(event);
      },
      false
    );
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  const generateReCaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      },
      authentication
    );
  };

  const phoneNumber = useFormik({
    initialValues: { phoneCode: "", phoneNumber: "", mode: "" },
    validateOnChange: false,
    validationSchema: yup.object().shape({
      phoneCode: yup.string().required(language.REQUIRED),
      phoneNumber: yup.string().required(language.REQUIRED),
    }),
    onSubmit: async (e) => {
      try {
        setBtnLoading(true);
        //alert(ipAddress);
        const phoneCodeAndNumber = e.phoneCode + e.phoneNumber;
        // alert(phoneNumber);
        var CryptoValue = Crypto.AES.encrypt(
          phoneCodeAndNumber,
          constantUtils.CONST_KEY
        ).toString();
        // alert(constantUtils.CONST_KEY);
        console.log("CryptoValue");
        // console.log(CryptoValue);
        const { data } = await axios.post(
          A.HOST + A.USER_LOGIN_IN_OTP,
          {
            phoneCode: e.phoneCode,
            phoneNumber: e.phoneNumber,
            bookingFrom: constantUtils.CONST_WEBAPP,
            userName: CryptoValue,
            type: constantUtils.CONST_WEB_OS,
          },
          header
        );
        console.log(data);
        setMode(data.mode);
        otp.setFieldValue("mode", data.mode);
        otp.setFieldValue("smsType", data.smsType);
        phoneNumber.setFieldValue("mode", data.mode);
        otp.setFieldValue("phoneCode", e.phoneCode);
        otp.setFieldValue("phoneNumber", e.phoneNumber);
        if (data.code === 200) {
          setStep(2);
          setEmail(data.email);
          setError("");
          setTimeout(() => setShowReSendOtp(true), 10000);
          if (data.mode === constantUtils.CONST_PRODUCTION) {
            if (data.smsType === constantUtils.CONST_FIREBASE) {
              setShowGoogle(true);
              generateReCaptcha();

              const phone =
                phoneNumber.values.phoneCode + phoneNumber.values.phoneNumber;
              const appVerifier = window.recaptchaVerifier;

              signInWithPhoneNumber(authentication, phone, appVerifier)
                .then((confirmationResult) => {
                  window.confirmationResult = confirmationResult;
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          } else {
            console.log(data.OTP);
            otp.setFieldValue("otp", data.OTP);
          }
        } else if (data.code === 400) {
          setErrorPopup(true);
          setPopUpContent(data.message);
        }
      } catch (err) {}
      setBtnLoading(false);
      setResendLoading(false);
    },
  });
  // OTP SUBMIT
  let userdata;
  const otp = useFormik({
    initialValues: {
      phoneCode: "",
      phoneNumber: "",
      otp: "",
      bookingFrom: constantUtils.CONST_WEBAPP,
      mode: "",
      smsType: "",
    },
    onSubmit: async (e) => {
      setBtnLoading(true);
      // console.log(otp?.values?.mode);
      // console.log(phoneNumber.values.mode);
      // console.log("mode");
      // console.log(mode);
      if (otp.values.mode === constantUtils.CONST_PRODUCTION) {
        // console.log("otp.values.mode");
        // console.log(otp.values.mode);
        console.log(otp.values.smsType);

        if (otp.values.smsType === constantUtils.CONST_FIREBASE) {
          const confirmationResult = window.confirmationResult;
          confirmationResult
            .confirm(otp.values.otp)
            .then((result) => {
              const user = result.user;

              setKeepLogged(true);
              // const ifLog =
              userLogIn(e, keepLogged); ////////
              if (user && keepLogged) {
                setError("");
                setTimeout(() => {
                  if (user) {
                    if (popUp === "loginBeforeRide") {
                      navigate(NavLinks.RIDE_BOOKING);
                    } else {
                      navigate("/");
                    }
                  }
                }, 1000);
              }
              // userdata = userProfile;
              // console.log("userdata");
              // console.log(userdata);
            })
            .catch((error) => {
              alert(language.OTP_INCORRECT);
              setBtnLoading(false);
            });
        } else {
          const userLogInStatus = await userLogIn(e, keepLogged);
            // userProfileFetch();
            // console.log(user);
          if(userLogInStatus) {
            setKeepLogged(true);
            let userdeatils;
            setTimeout(() => {
              userdeatils = JSON.parse(localStorage.getItem("CBU"));
              // console.log(userdeatils);
              if (userdeatils && keepLogged) {
                setError("");
                setTimeout(() => {
                  if (userdeatils) {
                    if (popUp === "loginBeforeRide") {
                      navigate(NavLinks.RIDE_BOOKING);
                    } else {
                      navigate("/");
                    }
                  }
                }, 1000);
              }
            }, 1000);
          } else {
              alert(language.OTP_INCORRECT);
              setBtnLoading(false);
          }
        }
      } else {
        // console.log(userProfileData);
        // console.log(user);

        userLogIn(e, keepLogged);
        // userProfileFetch();
        // console.log(user);

        setKeepLogged(true);
        let userdeatils;
        setTimeout(() => {
          userdeatils = JSON.parse(localStorage.getItem("CBU"));
          // console.log(userdeatils);
          if (userdeatils && keepLogged) {
            setError("");
            setTimeout(() => {
              if (userdeatils) {
                if (popUp === "loginBeforeRide") {
                  navigate(NavLinks.RIDE_BOOKING);
                } else {
                  navigate("/");
                }
              }
            }, 1000);
          }
        }, 1000);
      }
    },
  });

  useEffect(() => {}, [popUp]);
  if (errorPopup) {
    setTimeout(() => {
      setErrorPopup(false);
    }, 3000);
  }

  return (
    <>
      <Modal show={errorPopup} onHide={errorPopup}>
        <Modal.Title className="text-center">{popUpContent}</Modal.Title>
      </Modal>
      <main className="zervx_main">
        <div className="zervx_content">
          <section className="header-outer">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-4 col-4">
                  <div className="header-arrow">
                    {step === 1 ? (
                      <Link
                        to={
                          popUp === "loginBeforeRide"
                            ? NavLinks.RIDE_BOOKING
                            : NavLinks.HOME
                        }
                      >
                        <svg
                          className="ola-svg ola-icon"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <g>
                            <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                          </g>
                        </svg>
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-4">
                  <div className="header-logo">
                    <img src={settings?.mobileLogo} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- LOGIN --> */}

          <div className="px-lg-5">
            <section
              className="login-form-oute m-4 text-center rounded-xl pt-4"
              id="shadow"
            >
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    {step === 1 && (
                      <>
                        <div className="">
                          <h4>{language.ENTER_MOBILE_NUMBER}</h4>
                          <h6>{language.INFO_NOTIFY_SMS}</h6>
                        </div>
                        <form
                          onSubmit={phoneNumber.handleSubmit}
                          className="pt-3 lg:p-10"
                          style={{ maxWidth: 450 }}
                        >
                          <FieldWrapper>
                            <CountryCodesPicker
                              placeholder={language.DIAL_CODE}
                              defaultValue={phoneNumber.values.phoneCode}
                              change={(e) =>
                                phoneNumber.setFieldValue("phoneCode", e)
                              }
                              error={phoneNumber.errors.phoneCode}
                            />

                            <TextField
                              type="number"
                              placeholder={language.PHONE}
                              autofocus={true}
                              defaultValue={phoneNumber.values.phoneNumber}
                              change={(e) =>
                                phoneNumber.setFieldValue("phoneNumber", e)
                              }
                              error={phoneNumber.errors.phoneNumber}
                            />
                          </FieldWrapper>

                          <FieldWrapper>
                            <div className="w-full flex justify-center">
                              <button
                                id="submit"
                                placeholder="Next"
                                className={`p-2 bg-black rounded-lg text-white text-center flex `}
                              >
                                {btnLoading === true && (
                                  <div className="flex items-center justify-center">
                                    <div
                                      className="inline-block h-5 w-5 mr-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                      role="status"
                                    >
                                      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"></span>
                                    </div>
                                  </div>
                                )}
                                {language.SUBMIT}
                              </button>
                            </div>
                          </FieldWrapper>
                          <FieldWrapper>
                            {error !== "" && (
                              <p
                                className={
                                  "bg-white w-full text-center p-2 rounded-xl text-red-500"
                                }
                              >
                                {error}
                              </p>
                            )}
                          </FieldWrapper>
                        </form>
                      </>
                    )}
                    {step === 2 && (
                      <form
                        onSubmit={
                          otp.values.otp.length > 0 && btnLoading === false
                            ? otp.handleSubmit
                            : (e) => e.preventDefault()
                        }
                        className="p-3 lg:p-10"
                        style={{ maxWidth: 450 }}
                      >
                        <FieldWrapper padding={false}>
                          {resendLoading === true ? (
                            <h1 className="text-3xl">
                              {language.RESENDING_OTP}
                            </h1>
                          ) : (
                            <h1 className="text-3xl">{language.ENTER_PIN}</h1>
                          )}
                        </FieldWrapper>
                        <FieldWrapper>
                          <div
                            className=" text-gray-500"
                            style={{ fontSize: 12 }}
                          >
                            {language.INFO_SMS_SEND + " " +
                              phoneNumber.values.phoneCode +
                              " " +
                              phoneNumber.values.phoneNumber}
                            ,
                          </div>
                        </FieldWrapper>

                        <TextField
                          type="password"
                          align={"center"}
                          autofocus={true}
                          change={(e) => otp.setFieldValue("otp", e)}
                          value={otp.values.otp}
                          maxLength="6"
                          minLength="4"
                          error={error === "" ? "" : error}
                        />
                        {/* <Countdown
                          // date={new Date(bookingTime) + 10000}
                          date={new Date()}
                          intervalDelay={0}
                          precision={3}
                          renderer={(props) => (
                            <>
                              {/* <div>{alert(JSON.stringify(props.total))}</div> */}
                        {/* <div>
                              {props.total === 0
                                ? navigate(NavLinks.HOME)
                                : props.hours === props.hours - 1 &&
                                  navigate(NavLinks.HOME)}
                            </div> *
                              <div>
                                {props.hours}:{props.minutes}:{props.seconds}
                              </div>
                            </>
                          )}
                        /> */}
                        <div className="w-full flex justify-center mt-2">
                          <button
                            id="submit"
                            placeholder="Next"
                            className={`p-2 bg-black rounded-lg text-white text-center flex `}
                          >
                            {btnLoading === true && (
                              <div className="flex items-center justify-center">
                                <div
                                  className="inline-block h-5 w-5 mr-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                                  role="status"
                                >
                                  <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                                    {/* Loading... */}
                                  </span>
                                </div>
                              </div>
                            )}
                            {language.SUBMIT}
                          </button>
                        </div>
                        <hr className="my-1 dark:bg-gray-400" />
                        <div className="flex flex-col justify-center">
                          <p
                            onClick={() => setStep(1)}
                            className="text-sm mt-2 text-gray-500 text-center cursor-pointer hover:underline"
                          >
                            {language.CLICK_CHANGE_NUMBER}
                          </p>
                        </div>
                      </form>
                    )}
                  </div>
                  {/* <div id="recaptcha-container"></div> */}
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="zervx_rightimg">
          <img
            src={settings?.bookingAppImage}
            alt=""
            className="w-screen h-screen"
          />

          <div className="global-img-right">
            <h2>{settings?.bookingAppHomePageMessage}</h2>
          </div>
        </div>
      </main>
    </>
  );
}
